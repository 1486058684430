import React, {useState} from "react";
import {FieldRenderProps} from "react-final-form";

export type RenderTextInputProps = FieldRenderProps<string, any>;

export const RenderTextInput: React.FC<RenderTextInputProps> = ({input, meta, ...rest}: RenderTextInputProps) => (
    <input type={"text"} {...input} {...rest} />
)

export const RenderEmailInput: React.FC<RenderTextInputProps> = ({input, meta, ...rest}: RenderTextInputProps) => (
    <input type={"email"} {...input} {...rest} />
)

export const RenderPasswordInput: React.FC<RenderTextInputProps> = ({input, meta, ...rest}: RenderTextInputProps) => {
    let [showPassword, setShowPassword] = useState(false);

    return (
        <div>
            <input type={(showPassword ? "text" : "password")} {...input} {...rest} />
            <label style={{marginTop: '10px'}}>
                <input type={"checkbox"} onClick={() => setShowPassword(!showPassword)}/> Show Password
            </label>
        </div>
    )
}