import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";
import {
    FetchMetTimeSeriesQuery,
    FieldMap,
    ModelCommand,
    TimeSeries,
    ModelType
} from "./types";
import {apiLocal} from "./fieldMapApiLocal";
import {api} from "./fieldMapApi"
import config from "../../app/config"
import {RootState} from "../../app/store";
import {createBaseQueryWithReAuth} from "../../AuthApiSlice";

// @TODO I don't like that the api has to know this
//      but for now this solves a circular dependency with AuthApiSlice and AuthSlice
import {logout, authenticateUserResponseReceived} from '../../AuthSlice'

const activeApi = config.USE_LOCAL_DATA ? apiLocal : api;


const baseQuery = fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders (headers, {getState}) {
        const token = (getState() as RootState).auth.idToken;
        if(token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
})

export const fieldMapApiSlice = createApi({
    reducerPath: "api",
    baseQuery: retry(createBaseQueryWithReAuth(baseQuery, authenticateUserResponseReceived, logout)),
    endpoints(builder) {
        return {
            fetchFieldMap: builder.query<FieldMap, string>(activeApi.fetchFieldMap),
            fetchMetTimeSeries: builder.query<TimeSeries[], FetchMetTimeSeriesQuery>(activeApi.fetchMetTimeSeries),
            fetchModelInstance: builder.query<any, string>(activeApi.fetchModelInstance),
            putModelInstanceRequest: builder.mutation<any, ModelCommand>(activeApi.putModelInstanceRequest),
            fetchModelTypes: builder.query<ModelType[], void>(activeApi.fetchModelTypes)
        }
    }
})

export const { useFetchFieldMapQuery, useFetchMetTimeSeriesQuery } = fieldMapApiSlice;
