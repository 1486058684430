import React from "react";
import {DateTime} from "luxon";
import {ModelResult} from "../modelResultsSlice";
import "react-datepicker/dist/react-datepicker.css";
import {EmissionsGraph} from "../../fieldMap/EmissionsGraph"


type EmissionsQuantificationLegendProps = {
    modelResult: ModelResult
}

const EmissionsQuantificationLegend: React.FC<EmissionsQuantificationLegendProps> = ({modelResult}: EmissionsQuantificationLegendProps)  => {

    return (
        <div>
            <ul className="list-inline" style={{marginBottom: 0}}>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>Result</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    {DateTime.fromISO(modelResult.request_json.detection_datetime)
                        .toLocal().toLocaleString(DateTime.DATETIME_SHORT)}
                </li>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>CH4 Above Background:</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    {modelResult.response_json.ch4_concentration.toFixed(2) + " ppm"}
                </li>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>Methane Emission Rate:</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    {(modelResult.response_json.ch4_emission_rate * 3.6).toFixed(2) + " kg/hr"}
                </li>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>CH4 Background Concentration (ppm):</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    {modelResult.response_json.ch4_background.toFixed(2) + " ppm"}
                </li>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>Estimated Source Distance:</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    {modelResult.response_json.ch4_distance.toFixed(0) + " m"}
                </li>
            </ul>
            <ul className="list-inline" style={{height: 300}}>
                <li className="list-inline-item" style={{height: 300}}>
                    <EmissionsGraph modelResult={modelResult}/>
                </li>
            </ul>
        </div>
    )
}

export default EmissionsQuantificationLegend