import {FetchMetTimeSeriesQuery, MetSite, ModelCommand} from "./types";
import {FetchBaseQueryError} from "@reduxjs/toolkit/query";
import {generateTimeSeriesOneDayOfFiveMin, windDirectionsSampleSet, windSpeedsSampleSet} from "./sample/TimeSeries"
import { DateTime } from "luxon";



export const api = {
    fetchFieldMap: {
        query: (organization_id: string) =>  `fieldmap?organization_id=${organization_id}`,
        transformResponse: (response: any) => response.fieldmap
    },
    fetchMetTimeSeries: {
        query: ({date, metStationIds}: FetchMetTimeSeriesQuery) => {
            const site_ids = metStationIds.join(',')
            const dt = DateTime.fromISO(date)
            const start_dt = dt.startOf('day').toUTC().toISO();
            const end_dt = dt.endOf('day').toUTC().toISO();

            return `timeseries/?measure_type=wind&site_ids=${site_ids}&start_dt=${start_dt}&end_dt=${end_dt}`
        },
        transformResponse: (response: any, context: any) => {

            if (context.request.url.includes('site_ids=-999')){
                const end_dt = context.request.url.substr(-24)
                const start_dt = DateTime.fromISO(end_dt).toUTC().minus({hours:24, seconds:59})

                return [
                        generateTimeSeriesOneDayOfFiveMin('windSpeed', {"uom":"M/S",'siteId': '-999'}, start_dt, windSpeedsSampleSet),
                        generateTimeSeriesOneDayOfFiveMin('windDirection', {"uom":"M/S",'siteId': '-999'}, start_dt, windDirectionsSampleSet)
                    ]

            }

            return response.time_series
        }
    },
    fetchModelInstance: {
        queryFn: async (id: string, _queryApi: any, _extraOptions: any, fetchWithBQ: any) => {
            // @TODO get this into config
            const attempts = 10;
            const max_backoff = 5;
            for(let i = 0; i < attempts; i++) {
                const response = await fetchWithBQ(`model/${id}`)

                if(response.data && response.data.modelinstance && response.data.modelinstance.completed_at ) {
                    return { data: response.data.modelinstance }
                }
                else if(response.error && response.error.status !== 404) {
                    return { error: response.error as FetchBaseQueryError }
                }
                let backoff = Math.min(1 + (0.2 * (2 ** (i+1))), max_backoff) * 1000
                await new Promise(resolve => setTimeout(resolve, backoff))
            }

            return { error: {status: 'CUSTOM_ERROR', error: "Modeling timed out"} as FetchBaseQueryError }
        }
    },
    putModelInstanceRequest: {
        query: (body: ModelCommand) => {
            return {
                url: `model`,
                method: 'PUT',
                body
            }
        },
        transformResponse: (response: any) => response.modelinstance
    },
    fetchModelTypes: {
        query: () => ({
            url: 'model_type',
            method: 'GET'
        }),
        transformResponse: (response: any) => response.model_types
    }
}
