import {createApi, fetchBaseQuery, retry} from "@reduxjs/toolkit/query/react";
import config from "../../app/config";
import {RootState} from "../../app/store";
import {createBaseQueryWithReAuth} from "../../AuthApiSlice";
import {authenticateUserResponseReceived, logout} from "../../AuthSlice";
import {ModelType} from "../fieldMap/types";

type ModelTypeForm = {
    label: string
    formName: string
    legendName: string
}

export const ModelTypeForms: { [key: string]: ModelTypeForm } = {
    "54ad096e-adc8-4d81-b177-45c8826c47ee": {
        "label": "where it came from",
        "formName": "ReverseDispersionForm",
        "legendName": "ReverseDispersionLegend"
    },
    "e2cd3548-cec5-4e65-8479-3ca2583659fc": {
        "label": "where it is going",
        "formName": "ForwardDispersionForm",
        "legendName": "ForwardDispersionLegend"
    },
    "7a390185-9bde-45f1-a61e-d47e49ee7e04": {
        "label": "how much is there",
        "formName": "EmissionsQuantificationForm",
        "legendName": "EmissionsQuantificationLegend"
    }
}

const baseQuery = fetchBaseQuery({
    baseUrl: config.API_BASE_URL,
    prepareHeaders (headers, {getState}) {
        const token = (getState() as RootState).auth.idToken;
        if(token) {
            headers.set('Authorization', `Bearer ${token}`)
        }
        return headers
    }
})

export const ModelTypeApiSlice = createApi({
    reducerPath: "modeltypeapi",
    baseQuery: retry(createBaseQueryWithReAuth(baseQuery, authenticateUserResponseReceived, logout)),
    endpoints(builder) {
        return {
            fetchModelTypes: builder.query<ModelType[], void>({
                query: async () => ({
                    url: 'model_type',
                    method: 'GET'
                })
            })
        }
    }
})

export const selectModelTypeForm = (state: RootState, modelTypeId: string): ModelTypeForm | undefined =>
    ModelTypeForms[modelTypeId]