import { DateTime } from "luxon";

const windDirectionsSampleSet = [115.4,124.7,150.0,112.6,72.4,152.6,128.4,63.3,121.5,121.4,100.7,125.7,142.2,105.1,128.7,130.9,128.8,136.0,125.5,118.3,83.2,117.1,103.3,135.9,130.2,148.9,105.2,131.4,145.5,95.7,150.0,94.8,144.2,103.1,119.4,123.0,72.9,93.5,143.6,97.3,145.1,72.5,139.1,92.6,104.0,179.3,124.5,122.1,117.9,66.1,117.8,146.4,139.4,117.6,102.2,113.8,135.9,76.1,139.2,138.7,137.6,65.6,114.1,132.3,102.9,134.0,117.7,144.4,76.3,104.7,125.5,105.7];
const windSpeedsSampleSet = [3.0274,4.1316,3.9788,3.0364,2.054,4.1324,4.5516,1.6602,5.5738,4.0674,2.1356,2.9204,4.0406,3.7804,5.7204,5.7076,3.1894,1.5896,4.1156,3.5802,4.166,5.0638,4.0834,5.3286,3.5334,1.4102,3.2414,4.4728,4.5064,3.052,3.6786,2.784,3.8146,2.4486,4.5392,4.2772,3.5744,2.9124,5.4654,4.3524,4.6994,1.9562,3.6742,4.0268,3.9894,3.9366,3.048,3.349,4.4446,2.7012,5.4424,3.8514,3.7808,3.2438,4.0314,3.0664,3.6778,1.4256,6.2674,6.3558,4.1538,3.2938,3.1376,4.534,2.2538,4.0796,3.9278,3.7756,3.0482,4.7134,3.5016,5.1508];

const shuffleArray = (arr: number[]) => {
    return arr.map(v => ({v, sort: Math.random()}))
        .sort((a,b) => a.sort - b.sort)
        .map(({v}) => v)
}

const generateTimeSeriesOneDayOfFiveMin = (measureName: string, dimensions: object, date: DateTime, recordSet: number[]) => {

    const shuffledRecords = shuffleArray(recordSet)

    const dates = Array.from(Array(1440).keys())
        .map(n => n*1)
        .map(n => date.plus({minutes: n}))
        .map(d => d.toFormat('yyyy-MM-dd HH:mm:ss'))

    const modulo = recordSet.length

    const zipped = dates.reduce((carry, d, i) => ({...carry, [d]: shuffledRecords[i % modulo]}), {})

    return {
        measureName: measureName,
        dimensions: dimensions,
        records: zipped
    }
}

export {generateTimeSeriesOneDayOfFiveMin, windDirectionsSampleSet, windSpeedsSampleSet};