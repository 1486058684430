import {PropsWithoutRef, useContext, useEffect, useRef} from "react";
import {MapContext} from "./MapContext";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";


export const MyVectorLayer = ({style, zIndex = 0, layerName, features}: PropsWithoutRef<any>) => {

    const {map} = useContext(MapContext);
    const layerRef = useRef(new VectorLayer())
    const sourceRef = useRef(new VectorSource());
    const styleRef = useRef({})

    useEffect(() => {
        if (!map) return;

        sourceRef.current = new VectorSource();

        layerRef.current = new VectorLayer({
            source: sourceRef.current,
            style: style
        })


        map.addLayer(layerRef.current)
        layerRef.current.setZIndex(zIndex)

        if(features) {
            createFeatures()
        }

        return () => {
            if (map) {
                map.removeLayer(layerRef.current)
            }
        }
    }, [map])


    const createFeatures = () => {
        if(features.length > 0) {
            sourceRef.current.clear()
            sourceRef.current.addFeatures(features)
        }
    }

    useEffect(() => {
        if(!sourceRef.current || !map) {
            return;
        }

        createFeatures();

        return () => {
            if(sourceRef.current) {
                sourceRef.current.clear();
            }
        }
    }, [features])

    return null;
}