import React, {PropsWithoutRef, useEffect} from "react";
import ReverseDispersionForm from "../modeling/ReverseDispersion/ReverseDispersionForm";
import ForwardDispersionForm from "../modeling/ForwardDispersion/ForwardDispersionForm";
import EmissionsQuantificationForm from "../modeling/EmissionsQuantification/EmissionsQuantificationForm"
import {ModelType} from "./types";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import {fieldMapApiSlice} from "./fieldMapApiSlice";
import {modelTypeUpdated} from "./fieldMapSlice";
import {ModelTypeForms} from "../modeling/ModelTypeSlice";

type ModelInputFormProps = {
    modelTypeId: string
}

// Changing the component type at runtime appears to be fine in React but an anti-pattern in TypeScript
// https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
// https://stackoverflow.com/questions/60122615/conditional-types-for-a-dynamic-react-component-in-typescript?rq=1
const ModelInputForm: React.FC<ModelInputFormProps> = ({modelTypeId}: ModelInputFormProps) => {
    if(!modelTypeId) {
        return null;
    }

    switch(ModelTypeForms[modelTypeId].formName) {
        case "ReverseDispersionForm":
            return <ReverseDispersionForm />
        case "ForwardDispersionForm":
            return <ForwardDispersionForm />
        case "EmissionsQuantificationForm":
            return <EmissionsQuantificationForm />
        default:
            return null;
    }
}

const ModelTypeSelect= ({modelTypes, value, onChange}: PropsWithoutRef<any>) => {

    let modelPermissions =  JSON.parse(useAppSelector((state) => state.auth.modelPermissions) as string)
    return (
        <select className={"form-control form-control-sm"}
                value={value}
                onChange={onChange}
        >
            {
                modelTypes && modelTypes.filter( (o: ModelType) =>
                    o.id in modelPermissions && modelPermissions[o.id] == "true"
                ).map((o: ModelType) =>
                <option key={o.id} value={o.id}>{ModelTypeForms[o.id].label}</option>
                )
            }
        </select>
    )
}

const ModelingSidebarContent = () => {

    const dispatch = useAppDispatch()

    const { data: modelTypes, isLoading: areModelTypesLoading } = fieldMapApiSlice.endpoints.fetchModelTypes.useQuery();

    const selectedModelTypeId = useAppSelector(state => state.fieldMap.selectedModelTypeId) as string

    useEffect(() => {
        if(modelTypes) {
            dispatch(modelTypeUpdated(modelTypes[0].id))
        }
    }, [areModelTypesLoading])

    return (
        <div>
            <div style={{flex: 1}}>
                <div className="col-12">
                    <label htmlFor="metStationId">I want to know</label>
                </div>
                <div className="col-12">
                    <ModelTypeSelect modelTypes={modelTypes}
                                     value={selectedModelTypeId}
                                     onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(modelTypeUpdated(e.target.value))}
                    />
                </div>
            </div>

            <div style={{flex: 100}}>
                <ModelInputForm modelTypeId={selectedModelTypeId}/>
            </div>
        </div>
    )
}

export default ModelingSidebarContent;