import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {ModelResult} from "../modelResultsSlice";
import {DateTime} from "luxon";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";

type ReverseDispersionLegendProps = {
    modelResult: ModelResult
}

const ReverseDispersionLegend: React.FC<ReverseDispersionLegendProps> = ({modelResult}: ReverseDispersionLegendProps)  => {
    return (
        <div>
            <ul className="list-inline" style={{marginBottom: 0}}>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>Result</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    {DateTime.fromISO(modelResult.request_json.detection_datetime)
                        .toLocal().toLocaleString(DateTime.DATETIME_SHORT)}
                </li>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>Confidence:</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    {modelResult.confidence}
                </li>

                <li className="confidence-tooltip">
                    <FontAwesomeIcon icon={faInfoCircle} />
                    <span className="confidence-tooltiptext">Choosing a more representative met station is more important than obtaining a better Confidence result. Use the most representative (usually the closest) met station regardless of Confidence result.</span>
                </li>
            </ul>
            <ul className="list-inline" style={{marginBottom: 0}}>
                <li className="list-inline-item d-none d-sm-inline">
                    <b>Source Probability</b>
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    <FontAwesomeIcon icon={faCircle} style={{color: 'rgba(255,0,0)'}}/>
                    Most likely
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    <FontAwesomeIcon icon={faCircle} style={{color: 'rgba(255,165,0)'}}/>
                    Moderately likely
                </li>
                <li className="list-inline-item" style={{color: 'white', fontSize: 'smaller'}}>
                    <FontAwesomeIcon icon={faCircle} style={{color: 'rgba(255,255,0)'}}/>
                    Less likely
                </li>
                <li className="list-inline-item">
                    <small>
                        Area outside shaded region is least likely source.
                        For near-surface air pollution sources only.
                    </small>
                </li>
            </ul>
        </div>
    )
}

export default ReverseDispersionLegend