import React, {useEffect} from 'react';

import {Form, Field, FormSpy} from 'react-final-form'
import "react-datepicker/dist/react-datepicker.css";

import {fieldMapApiSlice} from "../../fieldMap/fieldMapApiSlice";
import Spinner from "../../../Spinner";

import {modelFormUpdated} from "../../fieldMap/fieldMapSlice";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {SoofieSite} from "../../fieldMap/types";
import {v4 as uuidv4} from "uuid"
import {expectedDataAvailabilityBoundaryDateTime} from "../../fieldMap/WindDataHelpers";
import WindDataDatePicker from "../../fieldMap/WindDataDatePicker";
import {InputSelect, InputSelectOption} from "../../common/InputSelect";
import {EmissionUnitSelect} from "../../fieldMap/EmissionsUnitSelect";


interface Values {
    detectionDateTime: Date;
    soofieMethaneId: string;
    soofieMetId: string
}

const initialValues = {
    detectionDateTime: expectedDataAvailabilityBoundaryDateTime(30),
    soofieMethaneId: 'KERN_OIL_REFINERY-1',
    soofieMetId: 'KERN_OIL_REFINERY-1'
}

const EmissionsQuantificationForm: React.FC = () => {

    const userOrganizationId = useAppSelector(state => state.auth.organizationId) as string

    const { data: fieldMap, isLoading: isFieldMapLoading } = fieldMapApiSlice.endpoints.fetchFieldMap.useQuery(userOrganizationId);

    const selectedCoordinate = useAppSelector(state => state.fieldMap.selectedCoordinate)
    const organizationId = useAppSelector(state => state.auth.organizationId)
    const modelResultsStatus = useAppSelector(state => state.model.status)
    const lastModelInstanceId = useAppSelector(state => state.model.last_modelinstance_id)

    const dispatch = useAppDispatch()

    const onSubmit = async (values: Values) => {

        if(!values.soofieMethaneId) {
            // @TODO real final-form validation, use `validate` prop on Form
            window.alert("Please select a soofie methane station");
            return false;
        }
        if(!selectedCoordinate) {
            // @TODO real final-form validation, use `validate` prop on Form
            window.alert("Please select a source coordinate");
            return false;
        }

        if(!organizationId) {
            window.alert("Authentication error, please refresh the page")
            return false;
        }

        const modelinstance_id = uuidv4()
        dispatch(fieldMapApiSlice.endpoints.putModelInstanceRequest.initiate({
            modelinstance_id: modelinstance_id,
            organization_id: organizationId,
            // BABOON
            modeltype_id: "7a390185-9bde-45f1-a61e-d47e49ee7e04",
            model_input: {
                detection_datetime: values.detectionDateTime.toISOString(),
                methane_site_name: values.soofieMethaneId,
                met_site_name: values.soofieMetId,
                detection_coordinate: selectedCoordinate,
                emissions_unit: "g/s"
            }
        }))
        dispatch(fieldMapApiSlice.endpoints.fetchModelInstance.initiate(modelinstance_id))
    }

    const [allSoofieSites, setAllSoofieSites] = React.useState<SoofieSite[]>([])

    const soofieSiteOptions = allSoofieSites.map(s => ({
        value: s.id,
        label: s.id
    })) as InputSelectOption[]

    const metSoofieSiteOptions = allSoofieSites.filter(s => s['isMetSite']).map(s => ({
        value: s.id,
        label: s.id
    })) as InputSelectOption[]

    const emissionUnitOptions = [
        {
            "value": "kg/hr",
            "label": "kg/hr"
        }
    ] as InputSelectOption[]

    useEffect(() => {
        if (!fieldMap) {
            return;
        }

        setAllSoofieSites(fieldMap.map_json.soofie_sites);

        dispatch(modelFormUpdated({
            selectedDateTime: initialValues.detectionDateTime.toISOString(),
        }))
    }, [fieldMap, dispatch])

    const [lastFailureReference, setLastFailureReference] = React.useState<string>("")

    useEffect(() => {
        if(modelResultsStatus === 'failed' && lastModelInstanceId && lastModelInstanceId !== lastFailureReference) {
            window.alert("An unexpected error occurred.\nReference: "+lastModelInstanceId)
            setLastFailureReference(lastModelInstanceId)
        }
    }, [modelResultsStatus])


    return (
        <Form
            onSubmit={onSubmit}
            initialValues={{
                ...initialValues
            }}
            render={({ handleSubmit, submitting, pristine}) => (
                <form onSubmit={handleSubmit}>
                    <FormSpy onChange={state => {
                        dispatch(modelFormUpdated({
                            selectedDateTime: state.values.detectionDateTime.toISOString(),
                            selectedMetSiteId: state.values.soofieMetId,
                            selectedMethaneSiteId: state.values.soofieMethaneId
                        }))
                    }} />
                    <div className="col-12" style={{fontSize: "small"}}>
                        <div className="row mb-2">
                            <div className="col-12">
                                <label htmlFor="steps_select">
                                    &nbsp;
                                </label>
                            </div>
                            <div className="col-12">

                                <button className="btn w-100 btn-sm btn-primary"
                                        type="submit"
                                        id="run-control"
                                        disabled={submitting || modelResultsStatus === 'loading'}
                                >
                                    {submitting || modelResultsStatus === 'loading'
                                        ? <Spinner />
                                        : "Run" }
                                </button>
                            </div>
                        </div>
                        <hr />
                        <div className="row mb-2">
                            <div className="col-12">
                                <label htmlFor="detectionDateTime">Detection date and time (Pacific)</label>
                            </div>
                            <div className="col-12 datetime-picker-parent__full-width">
                                <Field<Date> name={"detectionDateTime"} offset={30}
                                             component={WindDataDatePicker}
                                />
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <label htmlFor="soofieMethaneId">Methane station</label>
                            </div>
                            <div className="col-12">
                                <Field<string> name={"soofieMethaneId"}
                                               component={InputSelect}
                                               className={"form-control form-control-sm"}
                                >
                                    {soofieSiteOptions.map(o => <option key={o.value} value={o.value}>{o.value}</option>)}
                                </Field>
                            </div>
                        </div>
                        <div className="row mb-2">
                            <div className="col-12">
                                <label htmlFor="soofieMetId">Met station</label>
                            </div>
                            <div className="col-12">
                                <Field<string> name={"soofieMetId"}
                                               component={InputSelect}
                                               className={"form-control form-control-sm"}
                                >
                                    {metSoofieSiteOptions.map(o => <option key={o.value} value={o.value}>{o.value}</option>)}
                                </Field>
                            </div>
                        </div>

                        <div className="row mb-2">
                            <div className="col-12">
                                <label htmlFor="emissionUnitId">Emissions Unit</label>
                            </div>
                            <div className="col-12">
                                {isFieldMapLoading
                                    ? <Spinner/>
                                    : <EmissionUnitSelect emissionUnitOptions={emissionUnitOptions}/>
                                }
                            </div>
                        </div>

                    </div>
                </form>
            )}
        />
    )
}

export default EmissionsQuantificationForm;