import React, {useState} from "react";
import aq360_logo from "../../aq360_logo.png";
import user_guide from "../../static/AQ360-UserGuide-20220812.pdf";
import {AuthUserDropdownMenu} from "../common/AuthUserDropdownMenu";
import ModelingSidebarContent from "./ModelingSidebarContent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {Modal, Button} from "react-bootstrap"

export const FieldMapSidebar = () => {

    return (

        <nav className="d-flex flex-column flex-shrink-0 p-3 text-white bg-dark" style={{width: "250px", flexBasis: ''}}>
            <div style={{flex: 1}}>
                <span className="navbar-brand">
                    <img src={aq360_logo} alt="AQ360"/>
                </span>
            </div>

            <hr />

            <div style={{flex: 100}}>
                <ModelingSidebarContent />
            </div>

            <div style={{flex: 1}}>
                <div>

                    <Disclaimer />
                    <p style={{fontSize: "small"}}>
                        See the <a className="user-guide-link" href={user_guide} target={"blank"}>user
                        guide</a> for more.
                    </p>
                </div>
            </div>

            <hr />

            <div style={{flex: 1}}>
                <AuthUserDropdownMenu />
            </div>
        </nav>
    )
}


const DisclaimerModal = ({show, closeModal} : any) => {
    return (
        <Modal show={show} onHide={closeModal}>
            <Modal.Header>
                <Modal.Title>Disclaimer</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>
                    The AQ360 modeling tool supports situational awareness by providing approximate information on
                    (1) potential emission source areas that resulted in downwind impacts,
                    and (2) potential downwind impact areas from emission sources.
                    AQ360 is not intended as a replacement, and should not be used as a replacement,
                    for more sophisticated models that support emergency incident response.
                </p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="secondary" onClick={closeModal} onTouchStart={closeModal}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const Disclaimer = () => {

    const [show, setShow] = useState(true);

    const openModal = () => setShow(true);
    const closeModal= () => setShow(false);

    return (<div>
        <p style={{fontSize: "small"}}>
            <b>
                <a href={"#"} style={{color: 'white'}} onClick={openModal} onTouchStart={openModal}>
                    <FontAwesomeIcon icon={faInfoCircle} /> Not for emergency response.
                </a>
            </b>

        </p>
        {<DisclaimerModal closeModal={closeModal} show={show} />}
    </div>)
}
