import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AuthApiSlice} from "./AuthApiSlice";
import {AuthenticateUserResponse} from "./features/fieldMap/types";
import {RootState} from "./app/store";
import {ModelResult} from "./features/modeling/modelResultsSlice";

export interface AuthState {
    status: 'idle' | 'loading' | 'failed';
    isAuthenticated: boolean;
    accessToken?: string;
    accessTokenExpiration?: number;
    refreshToken?: string;
    username?: string;
    organizationId?: string;
    modelPermissions?: string
    idToken?: string;
}

const initialState: AuthState = {
    status: 'idle',
    isAuthenticated: false,
}

const authenticateUserResponseReceivedReducer = (state: AuthState, { payload:  {idTokenPayload, ...rest}  }: PayloadAction<AuthenticateUserResponse>) => {
    return {
        ...state,
        ...rest,
        isAuthenticated: true,
        isNewPasswordRequired: false,
        username: idTokenPayload.email,
        organizationId: idTokenPayload["custom:organization_id"],
        modelPermissions: idTokenPayload["custom:model_permissions"]
    }
}

export const AuthSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        hydrate: (state, action: PayloadAction<AuthState>) => {
            return action.payload
        },
        logout: (state) => {
            return {
                status: 'idle',
                isAuthenticated: false
            }
        },
        authenticateUserResponseReceived: authenticateUserResponseReceivedReducer,
        completeNewPasswordResponseReceived: authenticateUserResponseReceivedReducer
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            AuthApiSlice.endpoints.authenticateUser.matchFulfilled,
            authenticateUserResponseReceivedReducer
        );
        builder.addMatcher(
            AuthApiSlice.endpoints.completeNewPassword.matchFulfilled,
            authenticateUserResponseReceivedReducer
        );
    },
})

// we can count on username being an email, so let's split it
export const selectUsername = (state: RootState): string | undefined =>
    (state.auth.username && state.auth.username.indexOf('@') !== -1
        ? state.auth.username.split('@')[0]
        : state.auth.username)

export const {hydrate, logout, authenticateUserResponseReceived} = AuthSlice.actions;

export default AuthSlice.reducer