import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {GeoJSON} from "geojson";
import {fieldMapApiSlice} from "../fieldMap/fieldMapApiSlice";
import {RootState} from "../../app/store";
import {
    ForwardDispersionModelCommand,
    ReverseDispersionModelCommand,
    EmissionsCalculationModelCommand,
    EmissionsCalculationModelResponse
} from "../fieldMap/types";

export interface ModelResult {
    id: string;
    organization_id: string;
    created_at: string;
    completed_at: string;
    modeltype_id: string;
    request_json: ReverseDispersionModelCommand | ForwardDispersionModelCommand | EmissionsCalculationModelCommand;
    response_json:  EmissionsCalculationModelResponse | GeoJSON.FeatureCollection | any;
    visible: boolean;
    confidence: string;
}

export interface ModelResultsState {
    status: 'idle' | 'loading' | 'failed';
    modelResults: ModelResult[],
    last_modelinstance_id?: string
}

const initialState: ModelResultsState = {
    status: 'idle',
    modelResults: []
}

export const modelResultsSlice = createSlice({
    name: 'model',
    initialState,
    reducers: {
        modelExecutionStarted(state) {
            state.status = 'loading'
        }
    },
    extraReducers: (builder) => {
        builder.addMatcher(
            fieldMapApiSlice.endpoints.fetchModelInstance.matchPending,
            (state: ModelResultsState) => {
                state.modelResults = []
                state.status = 'loading'
            }
        )

        function conditionalFormatJson(str:string) {
            try {
                const json = JSON.parse(str);
                return json;
            } catch (e) {
                return str;
            }
        }

        builder.addMatcher(
            fieldMapApiSlice.endpoints.fetchModelInstance.matchFulfilled,
            // @TODO type this payload
            (state: ModelResultsState, action) => {
                const payload = action.payload
                const m: ModelResult = {
                    id: payload.id,
                    organization_id: payload.organization_id,
                    created_at: payload.created_at,
                    modeltype_id: payload.modeltype_id,
                    request_json: payload.request_json,
                    response_json: conditionalFormatJson(payload.response_json),
                    completed_at: payload.completed_at,
                    confidence: payload.confidence,
                    visible: true
                };

                state.modelResults = [m]
                state.status = 'idle'
                state.last_modelinstance_id = payload.id
            }
        )
        builder.addMatcher(
            fieldMapApiSlice.endpoints.fetchModelInstance.matchRejected,
            (state: ModelResultsState, action) => {

                state.modelResults = []
                state.status = 'failed'
                state.last_modelinstance_id = action.meta.arg.originalArgs
            }
        )
    }
})

export default modelResultsSlice.reducer

export const selectAllModelResults = (state: RootState): ModelResult[] =>
    state.model.modelResults

export const selectModelResultById = (state: RootState, modelId: string): ModelResult | undefined =>
    state.model.modelResults.find(m => m.id === modelId)
