import React, {useEffect, useRef, useState} from 'react';
import OSM from "ol/source/OSM";
import Map from "ol/Map";
import View from "ol/View";
import {fromLonLat, get} from "ol/proj";
import {Layers} from "../common/Layers";
import {MyTileLayer} from "../common/TileLayer";
import {MyVectorLayer} from "../common/VectorLayer";
import {MapContext} from "../common/MapContext";

import {coordinateSelected} from './fieldMapSlice'
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import Circle from "ol/geom/Circle";
import Feature from "ol/Feature";
import {MetSiteFeaturesLayer} from "./layer/MetSiteFeaturesLayer";
import {ModelResultsLayer} from "./layer/ModelResultsLayer";
import {fieldMapApiSlice} from "./fieldMapApiSlice";
import GeoJSON from "ol/format/GeoJSON";
import {FieldMapFeaturesLayer} from "./layer/FieldMapFeaturesLayer";
import config from "../../app/config"
import LoadingCover from "../../LoadingCover";
import {FieldMapSidebar} from "./FieldMapSidebar";
import {MapLegend} from "./MapLegend";


// https://taylor.callsen.me/using-openlayers-with-react-functional-components/
export const FieldMapContainer = () => {
    const selectedCoordinate = useAppSelector((state) => state.fieldMap.selectedCoordinate) as number[]

    const userOrganizationId = useAppSelector(state => state.auth.organizationId) as string

    const { data: fieldMap, isLoading: isFieldMapLoading } = fieldMapApiSlice.endpoints.fetchFieldMap.useQuery(userOrganizationId);
    const selectedModelTypeId = useAppSelector(state => state.fieldMap.selectedModelTypeId) as string

    const dispatch = useAppDispatch();
    const [map, setMap] = useState<Map>()
    const [currentZoom, setCurrentZoom] = useState(config.MIN_ZOOM)

    // the coersion here is necessary for all the typing to work correctly
    const mapRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>

    useEffect(() => {
        if(!fieldMap) {
            return;
        }

        document.title = "AQ360 | "+fieldMap.map_json.label;

        const initialMap = new Map({
            layers: [
            ],
            controls: [],
            view: new View({
                projection: 'EPSG:3857',
                center: fromLonLat(fieldMap.map_json.center),
                zoom: fieldMap.map_json.defaultZoom,
                minZoom: config.MIN_ZOOM,
                maxZoom: config.MAX_ZOOM,
            })
        })

        // @TODO see if we can get out of having to use closure here so this doesn't have to be so nested
        const handleMapClick = (e: any) => {
            if(!initialMap) {
                return;
            }

            const clickedCoord = initialMap.getCoordinateFromPixel(e.pixel);
            dispatch(coordinateSelected(clickedCoord));
        }

        const handleMoveEnd = (e: any) => {
            if(!initialMap) {
                return;
            }
            setCurrentZoom(initialMap.getView().getZoom())
        }

        if(mapRef.current) {
            initialMap.setTarget(mapRef.current)
        }

        initialMap.on('click', handleMapClick)
        initialMap.on('moveend', handleMoveEnd)
        setMap(initialMap)

        return () => initialMap.setTarget(undefined)
    }, [fieldMap, dispatch])

    let refineryFeatures = [] as Feature<any>[];
    if(fieldMap) {
        refineryFeatures = new GeoJSON().readFeatures(fieldMap.map_json.geoJSON, {
            featureProjection: get('EPSG:3857')
        })
    }

    if(isFieldMapLoading || !fieldMap) {
        return (
            <LoadingCover />
        )
    }

    // the order of div > MapContext.Provider vs the reverse doesn't seem to make a difference
    return (
        <div style={{display: "flex"}}>
            <FieldMapSidebar />

            <div ref={mapRef}
                 id={"map-container"}
                 className={"map-container"}
            >
                <MapContext.Provider value={{map: map}}>
                    <Layers>
                        <MyTileLayer source={new OSM()} zIndex={0} />

                        <FieldMapFeaturesLayer
                            zIndex={1}
                            zoom={currentZoom}
                            />

                        <ModelResultsLayer zIndex={2} />

                        <MetSiteFeaturesLayer zIndex={3} zoom={currentZoom} />

                        {selectedCoordinate && <MyVectorLayer
                            features={[
                                new Feature(
                                    new Circle(selectedCoordinate, 50)
                                )
                                ]}
                            zIndex={4}
                        />}

                    </Layers>
                </MapContext.Provider>

                <MapLegend />

            </div>
        </div>
    )
}