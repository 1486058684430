import React from 'react';
import {
    Chart as ChartJS,
    registerables
} from 'chart.js';
import { Scatter, Line } from 'react-chartjs-2';

import {ModelResult} from "../modeling/modelResultsSlice";

ChartJS.register(...registerables,);

type EmissionsGraphProps = {
    modelResult: ModelResult;
}

export const EmissionsGraph: React.FC<EmissionsGraphProps> = ({modelResult}: EmissionsGraphProps) => {

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        color:'white',
        scales:{
            x:{
                title:{
                    display: true,
                    text: 'Wind Direction (degrees)',
                    color:'white'
                },
                ticks:{
                    color:'white',
                },
                grid: {
                    color:'rgba(255,255,255,.5)'
                }

            },
            y:{
                beginAtZero: true,
                title:{
                    display: true,
                    text: 'CH4 Above Background (ppm)',
                    color:'white'
                },
                ticks:{
                    color:'white'
                },
                grid: {
                    color:'rgba(255,255,255,.5)'
                }
            }
        },
        plugins: {
            legend: {
                position: 'top' as const,
                color:'white'
            },
            title: {
                display: true,
                text: 'Methane Concentration vs Wind Direction',
                color:'white'
            },
            tooltip:{
                position: 'nearest',
                intersect: true,
                mode: 'index',
                callbacks:{
                    label: function(context: any){
                        let label = context.dataset.label || '';

                        if (label) {
                            label = label + ": " + context.formattedValue +"ppm"
                        }
                        return label;
                    }
                }
            }
        },
    };
    const ch4 = modelResult.response_json.ch4_wd_bin.ch4
    const ly = modelResult.response_json.ch4_wd_bin.ly
    const predDy = modelResult.response_json.ch4_wd_bin.predDy

    interface xy_data  {
        x: number;
        y: number;
    }
    let methane_scatter_data: xy_data []
    methane_scatter_data = []
    let methane_line_data: xy_data []
    methane_line_data = []

    ly.forEach( function (val:any, i:any) {
        methane_scatter_data.push({
            x:ly[i],
            y:ch4[i]
        })
        methane_line_data.push({
                x:ly[i],
                y:predDy[i]
            }
         )
    })

    methane_scatter_data.sort(function(a,b){return a.x - b.x})
    methane_line_data.sort(function(a,b){return a.x - b.x})

    const data = {
        datasets: [
            {
                label: 'Collected',
                data: methane_scatter_data,
                borderColor: 'rgba(255, 99, 132, 0.9)',
                backgroundColor: 'rgba(255, 99, 132, 0.9)',

            },
            {
                type: 'line',
                label:'Estimated',
                data: methane_line_data,
                tension: .25,
                borderColor: 'rgba(75, 192, 192, .9)',
                backgroundColor: 'rgba(75, 192, 192, .9)',
                radius:4,
                spanGaps: false

            }
        ]
    };

    // @ts-ignore
    return <Scatter options={options} data={data}/>
}
