import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';

import 'bootstrap/dist/css/bootstrap.css'
import {AuthState, hydrate} from "./AuthSlice";


// @TODO is there a better place for this?
// @TODO don't like this stringly-typed connection for the localStorage key 'aq360AuthState'
const getAuthStateFromLocalStorage = () => {
    try {
        const authState = localStorage.getItem('aq360AuthState')
        if(authState) {
            return JSON.parse(authState) as AuthState
        }
    } catch (e) {
        console.error(e)
    }
}

const authState = getAuthStateFromLocalStorage();
if(authState) {
    store.dispatch(hydrate(authState))
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
