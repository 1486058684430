import React, {useEffect} from "react";
import {useAppSelector} from "../../app/hooks";
import {ModelResult, selectAllModelResults} from "../modeling/modelResultsSlice";
import './MapLegend.css';

import {ModelTypeForms} from "../modeling/ModelTypeSlice";
import ReverseDispersionLegend from "../modeling/ReverseDispersion/ReverseDispersionLegend";
import ForwardDispersionLegend from "../modeling/ForwardDispersion/ForwardDispersionLegend";
import EmissionsQuantificationLegend from "../modeling/EmissionsQuantification/EmissionsQuantificationLegend";
import {DateTime} from "luxon";
import {fieldMapApiSlice} from "./fieldMapApiSlice";


type ModelLegendProps = {
    modelTypeId: string,
    modelResult: ModelResult
}


// Changing the component type at runtime appears to be fine in React but an anti-pattern in TypeScript
// https://reactjs.org/docs/jsx-in-depth.html#choosing-the-type-at-runtime
// https://stackoverflow.com/questions/60122615/conditional-types-for-a-dynamic-react-component-in-typescript?rq=1
const ModelLegend: React.FC<ModelLegendProps> = ({modelTypeId,modelResult}: ModelLegendProps)  => {
    if(!modelTypeId) {
        return null;
    }

    switch(ModelTypeForms[modelTypeId].legendName) {
        case "ReverseDispersionLegend":
            return <ReverseDispersionLegend modelResult={modelResult}/>
        case "ForwardDispersionLegend":
            return <ForwardDispersionLegend modelResult={modelResult}/>
        case "EmissionsQuantificationLegend":
            return <EmissionsQuantificationLegend modelResult={modelResult}/>
        default:
            return null;
    }
}

export const MapLegend  = () => {

    const modelResults = useAppSelector(selectAllModelResults) as ModelResult[]
    const userOrganizationId = useAppSelector(state => state.auth.organizationId) as string
    const { data: fieldMap, isLoading: isFieldMapLoading } = fieldMapApiSlice.endpoints.fetchFieldMap.useQuery(userOrganizationId);

    const [label, setLabel] = React.useState<string>("")

    useEffect(() => {
        if (!fieldMap || !modelResults.length) {
            return;
        }

    }, [fieldMap, modelResults])

    if (modelResults.length) {
        let result: ModelResult = modelResults[0];

        return (
            <div className={"map-info-container"}>
                <div className={"map-info"}>
                    <div>
                        <ModelLegend
                            modelTypeId={result.modeltype_id}
                            modelResult={result}
                        />
                    </div>

                </div>
                <div style={{textAlign: "right"}}>
                            <span style={{
                                fontSize: "small",
                                margin: "0 10px 0 0",
                                textAlign: "right",
                                background: "rgba(255,255,255,0.5)"
                            }} className={"text-dark"}>
                                Map data ©<a href={"https://www.openstreetmap.org/copyright"}
                                             target={"_blank"}>OpenStreetMap</a> contributors.
                            </span>
                </div>
            </div>
        )
    }
    return null;
}