import Style from "ol/style/Style";
import LineString from "ol/geom/LineString";
import Feature from "ol/Feature";

const LineFeature = function(start: number[], end: number[], style: Style | Style[]) {
    const lineFeature = new Feature(
        new LineString([
            start,
            end
        ])
    )

    if(style) {
        lineFeature.setStyle(style)
    }

    return lineFeature
}

export default LineFeature;