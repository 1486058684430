import React from 'react';
import styles from "./cover.module.css";
import {Field, Form, FormRenderProps} from "react-final-form";
import Spinner from "./Spinner";
import {useConfirmPasswordMutation} from "./AuthApiSlice";
import {Link} from "react-router-dom";
import { RenderPasswordInput } from "./features/common/FinalForm";

interface ForgotPasswordProps extends FormRenderProps {
    errorMessage: string,
    isLoading: boolean
}

const ConfirmPasswordForm: React.FC<any> = ({ handleSubmit, isLoading, errorMessage }: ForgotPasswordProps) => {
    return (
        <form onSubmit={handleSubmit}>
            <div>
                <p>
                    Please choose a new password.
                </p>
            </div>
            <hr />
            <div className={"input-form form-group mb-3"}>
                <label htmlFor="password" className={"form-label"}>
                    Password
                </label>
                <Field<string> name={"password"}
                               component={RenderPasswordInput}
                               placeholder={"********"}
                               className={"form-control"}
                />
            </div>
            <div className={"input-form form-group"}>
                <div className="d-grid gap-2">
                    <button className={"btn btn-primary"}
                            type={"submit"}
                            disabled={isLoading}
                    >
                        {isLoading
                            ? <Spinner />
                            : "Confirm New Password"}
                    </button>
                </div>
            </div>
            {!isLoading && errorMessage && <div className={"alert alert-danger"} style={{marginTop: 10}}>
                <p>{errorMessage}</p>
            </div>}
        </form>
    )
}

interface ConfirmPasswordValues {
    password: string;
}
const confirmPasswordInitialValues = {
    password: ""
}

export const ResetPassword: React.FC = () => {

    const [confirmPassword, { isLoading: isSubmittingConfirmPassword, error: confirmPasswordErrorMessage, isSuccess: confirmPasswordSuccess }] = useConfirmPasswordMutation()

    const searchParams = new URLSearchParams(window.location.search);
    const email = searchParams.get("email") || "";
    const code = searchParams.get("code") || "";

    const onSubmitConfirmPassword = (values: ConfirmPasswordValues) => {
        confirmPassword({
            ...values,
            username: email,
            verificationCode: code
        })
    }

    return (

        <div className={styles.coverContainer}>
            <div className={"d-flex h-100 p-3 mx-auto flex-column "+styles.coverInner}>
                <header className={"mb-auto"}>
                    <div className={"inner"}>

                    </div>
                </header>
                <main role={"main"} className={"inner "+styles.cover}>
                    <div className={"card"} style={loginStyles.card}>
                        <div className={"card-header"}>
                            <h3>AQ360 Password Reset</h3>
                        </div>
                        <div className={"card-body"}>

                            {!confirmPasswordSuccess && <Form
                                render={ConfirmPasswordForm}
                                initialValues={{
                                    ...confirmPasswordInitialValues
                                }}
                                onSubmit={onSubmitConfirmPassword}
                                isLoading={isSubmittingConfirmPassword}
                                errorMessage={confirmPasswordErrorMessage}
                            />}
                            {confirmPasswordSuccess && <div>
                                <p>Your password has been reset.</p>
                                <p>Return to <Link to={"/login"}>Sign in</Link></p>
                            </div>}
                        </div>
                    </div>
                </main>
                <footer className={"mt-auto"}>
                    <div className={"inner"}>
                    </div>
                </footer>
            </div>
        </div>
    )
}

const loginStyles = {
    card: {
        textAlign: 'left',
        color: '#212529',
        width: '400px'
    } as React.CSSProperties
}