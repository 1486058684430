
class Vector2D {
    public x: number;
    public y: number;

    constructor(x: number, y: number)
    {
        this.x = (typeof x !== 'undefined' ? x : 0)
        this.y = (typeof y !== 'undefined' ? y : 0)
    }

    sum(v2: Vector2D) {
        return new Vector2D(this.x+v2.x, this.y+v2.y)
    }

    diff(v2: Vector2D) {
        return new Vector2D(this.x-v2.x, this.y-v2.y);
    }

    scaled(s: number) {
        return new Vector2D(this.x*s, this.y*s)
    }

    length() {
        return Math.sqrt(this.lengthSquared())
    }

    lengthSquared() {
        return this.x**2 + this.y**2
    }

    normalized() {
        return new Vector2D(this.x/this.length(), this.y/this.length())
    }

    rotated(angle: number) {
        let theta = angle * (Math.PI / 180)
        return new Vector2D(
            (this.x*Math.cos(theta)) - (this.y*Math.sin(theta)),
            (this.x*Math.sin(theta)) + (this.y*Math.cos(theta))
        )
    }

    components() {
        return [this.x, this.y]
    }

}

export default Vector2D;