import {FieldRenderProps} from "react-final-form";
import React, {forwardRef, InputHTMLAttributes} from "react";
import DatePicker from "react-datepicker";
import {DateTime} from "luxon";
import {expectedDataAvailabilityBoundaryDateTime} from "./WindDataHelpers";

type DatePickerProps = FieldRenderProps<Date, any>

const DatePickerCustomInput = forwardRef(({ value, onClick, className, ...rest} : React.DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, ref: React.Ref<HTMLInputElement>) => (
    <input className={"form-control form-control-sm".concat(className ? " "+className : "")} style={{width: '100%'}} onClick={onClick} value={value} {...rest} />
));

const WindDataDatePicker = ({ name, offset, input, input: { value } }: DatePickerProps) => {
    return (
        <DatePicker
            locale={"en"}
            selected={value ? value : null}
            name={name}
            dateFormat={"yyyy-MM-dd h:mm a"}
            showTimeSelect
            timeIntervals={5}
            customInput={<DatePickerCustomInput />}
            maxDate={new Date()}
            onChange={(date: Date) => {

                if(DateTime.fromJSDate(date).isValid) {
                    if(date >= expectedDataAvailabilityBoundaryDateTime(offset)) {
                        alert("Wind data is not yet available for your selected date and time")
                    } else {
                        input.onChange(date)
                    }
                } else {
                    input.onChange(null);
                }

            }}
        />
    )
}

export default  WindDataDatePicker;