import Style from "ol/style/Style";
import {Feature} from "ol";
import {Circle} from "ol/geom";
import {State} from "ol/render";
import {log_interpolate_range, range} from "../../../../interpolate";
import config from "../../../../app/config"
import {scaledPixel} from "../../../../util";

const SiteLabelFeature = function(coordinate: number[], style: null | Style, text: string, zoom: number)
{
    const radius = log_interpolate_range(config.MIN_ZOOM, config.MAX_ZOOM, 120, 1, zoom)
    const circleFeature = new Feature({
        geometry: new Circle(coordinate, radius)
    })

    if(style) {
        circleFeature.setStyle(style)
    } else {
        circleFeature.setStyle(defaultSiteLabelFeatureStyle(text, zoom));
    }

    return circleFeature
}

const defaultSiteLabelFeatureStyle = (text: string, zoom: number) => {
    return new Style({
        renderer: function (coordinates, state: State) {

            let font_size_small = 9
            let font_size_large = 18
            let coordinates_0 = coordinates[0] as number[];
            let x = coordinates_0[0];
            let y = coordinates_0[1];
            let coordinates_1 = coordinates[1] as number[];
            let x1 = coordinates_1[0];
            let y1 = coordinates_1[1];
            let dx = x1 - x;
            let dy = y1 - y;
            let radius = Math.sqrt(dx * dx + dy * dy);

            let ctx = state.context;

            ctx.beginPath();
            ctx.arc(x, y, radius, 0, 2 * Math.PI, true);

            let bgcolor = 'white'
            ctx.fillStyle = bgcolor;
            ctx.fill();
            ctx.strokeStyle =  'darkgrey'
            ctx.stroke();

            ctx.fillStyle = 'black'
            ctx.textAlign = "center";

            let fontSize = log_interpolate_range(config.MIN_ZOOM, config.MAX_ZOOM, scaledPixel(font_size_small), scaledPixel(font_size_large), zoom)


            ctx.font = 'bold '+fontSize+'px sans-serif';
            ctx.fillText(text, x, y+font_size_small/2);
        }
    })
}

export default SiteLabelFeature;