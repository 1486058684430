import Vector2D from "../../../../Vector2D";

const TriangleFeature = function(
    ctx: CanvasRenderingContext2D,
    radius: number,
    tip: Vector2D,
    direction: Vector2D,
    fillStyle: string | CanvasGradient | CanvasPattern
) {

    ctx.beginPath()
    ctx.fillStyle = fillStyle

    const center = direction.scaled(-1 * radius/2).sum(tip)

    const top = center.sum(direction.scaled(radius))
    const right = center.sum(direction.scaled(radius).rotated(120))
    const left = center.sum(direction.scaled(radius).rotated(240))

    ctx.moveTo(top.x, top.y)
    ctx.lineTo(right.x, right.y)
    ctx.lineTo(left.x, left.y)
    ctx.lineTo(top.x, top.y)
    ctx.fill()
}

export default TriangleFeature;