import React, {useEffect} from 'react';
import {useAppDispatch} from "./app/hooks";
import { Link } from 'react-router-dom';

import {logout} from "./AuthSlice"
import styles from './cover.module.css'

const Logout: React.FC = () => {

    const dispatch = useAppDispatch()
    useEffect(() => {
        dispatch(logout())

    }, [])

    return (
        <div className={styles.coverContainer}>
            <div className={"d-flex h-100 p-3 mx-auto flex-column "+styles.coverInner}>
                <header className={"mb-auto"}>
                    <div className={"inner"}>

                    </div>
                </header>
                <main role={"main"} className={"inner "+styles.cover}>
                    <h1 className={styles.coverHeading}>
                        Logged out
                    </h1>
                    <p className={"lead"}>
                        <Link to={"/"}>
                            <button className={"btn btn-secondary"}>
                                Return to AQ360
                            </button>
                        </Link>
                    </p>
                </main>
                <footer className={"mt-auto"}>
                    <div className={"inner"}>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default Logout;