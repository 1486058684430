import React from 'react';
import './App.css';
import {FieldMapContainer} from "./features/fieldMap/fieldMapContainer";
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import {Login} from "./Login";
import Logout from "./Logout";
import PrivateRoute from "./PrivateRoute"
import {ForgotPassword} from "./ForgotPassword";
import {ResetPassword} from "./ResetPassword";

function App() {
  return (
    <div className="App">
        <Router>
            <Switch>
                <Route path={"/"} exact={true}>
                    <Redirect to={"fieldmap"} />
                </Route>
                <PrivateRoute path={"/fieldmap"}>
                    <FieldMapContainer />
                </PrivateRoute>
                <Route path={"/login"} component={Login} />
                <Route path={"/logout"} component={Logout} />
                <Route path={"/forgotpassword"} component={ForgotPassword} />
                <Route path={"/resetpassword"} component={ResetPassword} />
            </Switch>
        </Router>
    </div>
  );
}

export default App;
