import React, {useEffect, useRef, useState} from 'react';
import {FieldRenderProps, Form, Field, FormRenderProps} from "react-final-form";
import Spinner from "./Spinner";
import {useAuthenticateUserMutation, useCompleteNewPasswordMutation} from "./AuthApiSlice";
import {useAppDispatch, useAppSelector} from "./app/hooks";
import {Redirect, Route} from "react-router-dom";
import styles from './cover.module.css'
import { Link } from 'react-router-dom';

import {RenderEmailInput, RenderPasswordInput} from "./features/common/FinalForm";


interface NewPasswordFormProps extends FormRenderProps {
    isSubmitting: boolean
    errorMessage: string
}

const NewPasswordForm: React.FC<any> = ({ handleSubmit, isSubmitting, errorMessage }: NewPasswordFormProps) => {
    return (
        <form onSubmit={handleSubmit}>
            <p>To complete your registration you must create a new password.</p>
            <hr />
            <div className={"input-form form-group mb-3"}>
                <label htmlFor="password" className={"form-label"}>
                    New Password
                </label>
                <Field<string> name={"password"}
                               component={RenderPasswordInput}
                               placeholder={"********"}
                               className={"form-control"}
                />
            </div>
            <div className={"input-form form-group"}>
                <div className="d-grid gap-2">
                    <button className={"btn btn-primary"}
                            type={"submit"}
                            disabled={isSubmitting}
                    >
                        {isSubmitting
                            ? <Spinner />
                            : "Confirm New Password"}
                    </button>
                </div>
            </div>
            {!isSubmitting && errorMessage && <div className={"alert alert-danger"} style={{marginTop: 10}}>
                <p>{errorMessage}</p>
            </div>}
        </form>
    )
}


interface LoginFormProps extends FormRenderProps {
    isAuthenticating: boolean
    errorMessage: string
}

const LoginForm: React.FC<any> = ({ handleSubmit, isAuthenticating, errorMessage }: LoginFormProps) => {
    return (
        <form onSubmit={handleSubmit}>
            <div className={"input-form form-group mb-3"}>
                <label htmlFor="username" className={"form-label"}>
                    Email Address
                </label>
                <Field<string> name={"username"}
                               component={RenderEmailInput}
                               placeholder={"email@domain.tld"}
                               className={"form-control"}
                />
            </div>
            <div className={"input-form form-group mb-3"}>
                <label htmlFor="password" className={"form-label"}>
                    Password
                </label>
                <Field<string> name={"password"}
                               component={RenderPasswordInput}
                               placeholder={"********"}
                               className={"form-control"}
                />
            </div>
            <div className={"input-form form-group"}>
                <div className="d-grid gap-2">
                    <button className={"btn btn-primary"}
                            type={"submit"}
                            disabled={isAuthenticating}
                    >
                        {isAuthenticating
                            ? <Spinner />
                            : "Sign In"}
                    </button>
                </div>
            </div>
            {!isAuthenticating && errorMessage && <div className={"alert alert-danger"} style={{marginTop: 10}}>
                <p>{errorMessage}</p>
            </div>}
        </form>
    )
}

interface Values {
    username: string;
    password: string;
}

const initialValues = {
    username: "",
    password: ""
}

const initialValuesNewPassword = {
    password: ""
}

export const Login: React.FC = () => {
    const dispatch = useAppDispatch()
    const [username, setUsername] = useState("");
    const [authenticateUser, { isLoading: isAuthenticating, error: errorMessage }] = useAuthenticateUserMutation()
    const onSubmit = (values: Values) => {
        setUsername(values.username)
        authenticateUser({
            ...values,
            // I might be able to avoid this by properly using the resolution of asyncAuthenticateUser
            newPasswordRequired: () => setNewPasswordRequired(true)
        })
    }

    const [completeNewPassword, { isLoading: isSubmittingNewPassword, error: errorMessageNewPassword }] = useCompleteNewPasswordMutation()
    const onSubmitNewPassword = (values: Values) => {
        completeNewPassword({
            ...values,
            username
        })
    }

    const isAuthenticated= useAppSelector(state => state.auth.isAuthenticated)

    let [isNewPasswordRequired, setNewPasswordRequired] = useState(false);

    if(isAuthenticated) {
        return (<Redirect to={"/"} />)
    }

    return (
        <div className={styles.coverContainer}>
            <div className={"d-flex h-100 p-3 mx-auto flex-column "+styles.coverInner}>
                <header className={"mb-auto"}>
                    <div className={"inner"}>

                    </div>
                </header>
                <main role={"main"} className={"inner "+styles.cover}>
                    <div className={"card"} style={loginStyles.card}>
                        <div className={"card-header"}>
                            <h3>AQ360 Sign In</h3>
                        </div>
                        {!isNewPasswordRequired && <div className={"card-body"}>
                            <Form
                                render={LoginForm}
                                initialValues={{
                                    ...initialValues
                                }}
                                onSubmit={onSubmit}

                                isAuthenticating={isAuthenticating}
                                errorMessage={errorMessage}
                            />
                        </div>}

                        {!isNewPasswordRequired && <div className={"card-footer text-center"}>
                            <Link to={"/forgotpassword"}>
                                Forgot password?
                            </Link>
                        </div>}

                        {isNewPasswordRequired && <div className={"card-body"}>
                            <Form
                            render={NewPasswordForm}
                            initialValues={{
                                ...initialValuesNewPassword
                            }}
                            onSubmit={onSubmitNewPassword}

                            isAuthenticating={isSubmittingNewPassword}
                            errorMessage={errorMessageNewPassword}
                            />
                            </div>}
                    </div>
                </main>
                <footer className={"mt-auto"}>
                    <div className={"inner"}>
                    </div>
                </footer>
            </div>
        </div>
    )
}

const loginStyles = {
    card: {
        textAlign: 'left',
        color: '#212529',
        width: '400px'
    } as React.CSSProperties
}