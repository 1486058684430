export {}

const windDirections = ["N","NNE","NE","ENE","E","ESE","SE","SSE","S","SSW","SW","WSW","W","WNW","NW","NNW"]

const windDirectionToString = (windDirection: number) : string => {
    if(windDirection != null) {
        // https://stackoverflow.com/a/7490772/1642169
        // http://snowfence.umn.edu/Components/winddirectionanddegreeswithouttable3.htm

        const val = Math.floor((windDirection / 22.5 + .5) % 16);
        if(val < windDirections.length ) { // this will probably always be true due to the modulo
            return windDirections[val];
        } else {
            return "N/A";
        }
    } else {
        return "N/A";
    }
}

export default windDirectionToString;