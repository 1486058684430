import Feature from 'ol/Feature.js';
import Polygon from 'ol/geom/Polygon.js';
import Point from 'ol/geom/Point.js';
import {Style} from "ol/style";
import {fromLonLat} from "ol/proj";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import Text from "ol/style/Text";

const PolygonFeature = function (feature: any) {
    const polygonFeature = new Feature({
        geometry: new Polygon(feature.geometry.coordinates.map((coordinateSet: any) => coordinateSet.map((coords: any)=> fromLonLat(coords)))),
        labelPoint: feature.labelLatLng ? new Point(fromLonLat(feature.labelLatLng)):  new Point([0,0]),
        name: feature.name ? feature.name:  "",
        properties:feature.properties
    })

    let polygonStyle = new Style({
        stroke: new Stroke({
            color: 'blue',
            width: 1
        }),
        fill: new Fill({
            color: "rgba(0,0,255,0.2)"
        }),
        text: new Text({
            textAlign: "center",
            fill: new Fill({color: "rgba(0,0,255,0.7)"}),
            text:"",
            stroke: new Stroke({color: "white", width: 1}),
            font: "bold 20px sans-serif",
            placement: "point",
        })
    })

    polygonStyle.getText().setText(polygonFeature.get('name'))
    polygonFeature.setStyle(polygonStyle)

    return polygonFeature
}

export default PolygonFeature;