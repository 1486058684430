import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
    retry
} from "@reduxjs/toolkit/query/react";
import config from "./app/config";
import {
    AuthenticateUserRequest,
    AuthenticateUserResponse,
    ConfirmPasswordRequest,
    ResetPasswordRequest,
    CompleteNewPasswordRequest
} from "./features/fieldMap/types";
import {
    AuthenticationDetails,
    CognitoRefreshToken,
    CognitoUser,
    CognitoUserPool,
    CognitoUserSession
} from 'amazon-cognito-identity-js'
import {RootState} from "./app/store";
import {BaseQueryApi, BaseQueryResult, QueryReturnValue} from "@reduxjs/toolkit/dist/query/baseQueryTypes";

let cognitoUserPool: CognitoUserPool
let cognitoUser: CognitoUser;

const getCognitoUserPool = () => {
    if(!cognitoUserPool) {
        cognitoUserPool = new CognitoUserPool({
            UserPoolId: config.COGNITO_USER_POOL_ID,
            ClientId: config.COGNITO_CLIENT_ID
        })
    }
    return cognitoUserPool
}
const getCognitoUser = (username: string, newUser: boolean) => {
    if(!cognitoUser || newUser) {
        cognitoUser = new CognitoUser({
            Username: username,
            Pool: getCognitoUserPool()
        })
    }
    return cognitoUser
}

const asyncResetPassword = (cognitoUser: CognitoUser) => {
    return new Promise((resolve, reject) => cognitoUser.forgotPassword( {
        onSuccess: resolve,
        onFailure: reject,
    }))
}

const asyncConfirmPassword = (cognitoUser: CognitoUser, verificationCode: string, newPassword: string) => {
    return new Promise((resolve, reject) => cognitoUser.confirmPassword(verificationCode, newPassword, {
        onSuccess: resolve,
        onFailure: reject,
    }))
}

const asyncAuthenticateUser = (cognitoUser: CognitoUser, authenticationDetails: AuthenticationDetails, newPasswordRequired: Function) => {
    return new Promise((resolve, reject) => cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: resolve,
        onFailure: reject,
        newPasswordRequired: () => { newPasswordRequired(); resolve({"msg": "NEW_PASSWORD_REQUIRED"}); }
    }))
}

const asyncCompleteNewPasswordChallenge = (cognitoUser: CognitoUser, newPassword: string, userAttrs: object = {}) => {

    return new Promise((resolve, reject) => cognitoUser.completeNewPasswordChallenge(newPassword, userAttrs,{
        onSuccess: resolve,
        onFailure: reject,
    }))
}

const asyncRefreshUser = (cognitoUser: CognitoUser, cognitoRefreshToken: CognitoRefreshToken) => {
    return new Promise((resolve, reject) => cognitoUser.refreshSession(cognitoRefreshToken, (err, session) => {
        if(err) {
            reject(err)
        } else {
            resolve(session)
        }
    }))
}

const refreshAuth = async (username: string, refreshToken: string) => {

    const cognitoUser = getCognitoUser(username,false)

    const cognitoRefreshToken = new CognitoRefreshToken({
        RefreshToken: refreshToken
    })

    try {
        const refreshResult = await asyncRefreshUser(cognitoUser, cognitoRefreshToken) as CognitoUserSession

        if (refreshResult.getIdToken()) {
            const authenticateUserResponse = {
                idTokenPayload: refreshResult.getIdToken().decodePayload(),
                idToken: refreshResult.getIdToken().getJwtToken(),
                accessToken: refreshResult.getAccessToken().getJwtToken(),
                accessTokenExpiration: refreshResult.getAccessToken().getExpiration(),
                refreshToken: refreshResult.getRefreshToken().getToken()
            } as AuthenticateUserResponse

            return {
                data: authenticateUserResponse
            }
        } else {
            console.error("could not reauthenticate", refreshResult)

            // BABOON
            return { error: "could not reauthenticate"}
        }

    } catch (err) {
        console.error("could not reauthenticate", err)

        return { error: "could not reauthenticate"}
    }
}

export const createBaseQueryWithReAuth = (baseQuery: BaseQueryFn, successAction: Function, failureAction: Function)  => {
    return async (
        args: any,
        api: BaseQueryApi,
        extraOptions: {}
    ): Promise<QueryReturnValue<unknown, unknown, {} >> => {
        let result = await baseQuery(args, api, extraOptions) as BaseQueryResult<any>

        if(result.error && result.error.status === 401) {
            const {auth: {username, refreshToken}} = api.getState() as RootState

            if(!username || !refreshToken) {
                return result;
            }

            const refreshAuthResult = await refreshAuth(username, refreshToken)

            if (refreshAuthResult.data) {
                api.dispatch(successAction(refreshAuthResult.data))
            } else {
                api.dispatch(failureAction())
            }
        }

        return result
    }
}

export const AuthApiSlice = createApi({
    reducerPath: "authapi",
    baseQuery: fetchBaseQuery(),
    endpoints: (builder) => ({
        completeNewPassword: builder.mutation<unknown, unknown>({
            queryFn: async(completeNewPasswordRequest: CompleteNewPasswordRequest) => {

                const cognitoUser = getCognitoUser(completeNewPasswordRequest.username, false)


                try {
                    await asyncCompleteNewPasswordChallenge(cognitoUser, completeNewPasswordRequest.password)

                    const authResult = cognitoUser.getSignInUserSession()

                    if(authResult && authResult.getIdToken()) {
                        return {
                            data: {
                                idTokenPayload: authResult.getIdToken().decodePayload(),
                                idToken: authResult.getIdToken().getJwtToken(),
                                accessToken: authResult.getAccessToken().getJwtToken(),
                                accessTokenExpiration: authResult.getAccessToken().getExpiration(),
                                refreshToken: authResult.getRefreshToken().getToken()
                            } as AuthenticateUserResponse
                        }
                    } else {
                        console.error("Unexpected error setting new password: ", authResult)
                        return { error: "Unexpected error setting new password" }
                    }
                }
                catch (err) {
                    console.error("Unexpected error setting new password: ", err)
                    return { error: err.message }
                }
            }
        }),
        confirmPassword: builder.mutation<unknown, ConfirmPasswordRequest>({
            queryFn: async(confirmPasswordRequest: ConfirmPasswordRequest) => {

                const userPool = new CognitoUserPool({
                    UserPoolId: config.COGNITO_USER_POOL_ID,
                    ClientId: config.COGNITO_CLIENT_ID
                })

                let cognitoUser = new CognitoUser({
                    Username: confirmPasswordRequest.username,
                    Pool: userPool
                })

                try {
                    await asyncConfirmPassword(cognitoUser, confirmPasswordRequest.verificationCode, confirmPasswordRequest.password)

                    // has to return something to conform to return type
                    return {
                        data: {}
                    }
                }
                catch (err) {
                    console.error("Unexpected error setting new password: ", err)
                    return { error: err.message }
                }
            }
        }),
        resetPassword: builder.mutation<unknown, ResetPasswordRequest>({
            queryFn: async(resetPasswordRequest: ResetPasswordRequest) => {

                const userPool = new CognitoUserPool({
                    UserPoolId: config.COGNITO_USER_POOL_ID,
                    ClientId: config.COGNITO_CLIENT_ID
                })

                let cognitoUser = new CognitoUser({
                    Username: resetPasswordRequest.username,
                    Pool: userPool
                })

                try {
                    const authResult = await asyncResetPassword(cognitoUser) as CognitoUserSession

                    // has to return something to conform to return type
                    return {
                        data: {}
                    }
                }
                catch (err) {
                    console.error("Unexpected error requesting password rest: ", err)
                    return { error: err.message }
                }
            }
        }),
        // @TODO replace this unknown when the shape is better-known
        authenticateUser: builder.mutation<unknown, AuthenticateUserRequest>({
            queryFn: async (authUserRequest: AuthenticateUserRequest) => {
                // https://www.npmjs.com/package/amazon-cognito-identity-js
                // https://docs.aws.amazon.com/cognito/latest/developerguide/amazon-cognito-user-pools-using-tokens-with-identity-providers.html

                let authenticationDetails = new AuthenticationDetails({
                    Username: authUserRequest.username,
                    Password: authUserRequest.password
                })

                const cognitoUser = getCognitoUser(authUserRequest.username, true)

                try {
                    const resp = await asyncAuthenticateUser(cognitoUser, authenticationDetails, authUserRequest.newPasswordRequired)

                    const authResult = cognitoUser.getSignInUserSession()

                    if(authResult && authResult.getIdToken()) {
                        return {
                            data: {
                                idTokenPayload: authResult.getIdToken().decodePayload(),
                                idToken: authResult.getIdToken().getJwtToken(),
                                accessToken: authResult.getAccessToken().getJwtToken(),
                                accessTokenExpiration: authResult.getAccessToken().getExpiration(),
                                refreshToken: authResult.getRefreshToken().getToken()
                            } as AuthenticateUserResponse
                        }
                    } else {
                        console.error("Unexpected authentication error: ", authResult)
                        return { error: "Unexpected authentication error" }
                    }
                }
                catch (err) {
                    if(err.code && err.code === "PasswordResetRequiredException") {
                        return {error: "A password reset is required. Please check your email for instructions."}
                    }
                    console.error("Unexpected authentication error: ", err)
                    return { error: err.message }
                }

            }
        })
    })
})

export const {
    useAuthenticateUserMutation,
    useCompleteNewPasswordMutation,
    useResetPasswordMutation,
    useConfirmPasswordMutation
} = AuthApiSlice;