import React, {useEffect, useState} from "react";
import {MetSite, TimeSeries, VectorFeatureLayerProps} from "../types";
import {fieldMapApiSlice} from "../fieldMapApiSlice";
import {useAppSelector} from "../../../app/hooks";
import MetStationFeature from "./feature/MetStationFeature";
import {MyVectorLayer} from "../../common/VectorLayer";
import {DateTime} from "luxon"
import {mps2miph} from "../../../util";

export const MetSiteFeaturesLayer: React.FC<VectorFeatureLayerProps> = ({zIndex, zoom}: VectorFeatureLayerProps) => {

    const userOrganizationId = useAppSelector(state => state.auth.organizationId) as string

    const { data: fieldMap, isLoading: isFieldMapLoading } = fieldMapApiSlice.endpoints.fetchFieldMap.useQuery(userOrganizationId);

    const selectedMetSiteId = useAppSelector(state => state.fieldMap.selectedMetSiteId) as string
    const selectedDateTime = useAppSelector((state) => state.fieldMap.selectedDateTime) as string

    const [metSites, setMetSites] = React.useState<MetSite[]>([])
    const metSiteIds = metSites.map(s => s.id);

    const {data: metTimeSeries = []} = fieldMapApiSlice.endpoints.fetchMetTimeSeries.useQuery({
        date: DateTime.fromISO(selectedDateTime).startOf('day').toISO(),
        metStationIds: metSiteIds
    }, {skip: (metSites.length <= 0) || !selectedDateTime})

    const [metSiteFeatures, setMetSiteFeatures] = useState({})


    useEffect(() => {
        if (!fieldMap) {
            return;
        }

        setMetSites(fieldMap.map_json.met_sites);
    }, [fieldMap]);

    useEffect(() => {

        if (!fieldMap || metTimeSeries.length <= 0 || !zoom) {
            return;
        }


        const newMetSiteFeatures = metSites.map((s: MetSite) => {
            // @TODO move these to a selector
            const wsts = metTimeSeries.find((ts: TimeSeries) => ts.measureName === 'windSpeed' && ts.dimensions.siteId === s.id) as TimeSeries
            const wdts = metTimeSeries.find((ts: TimeSeries) => ts.measureName === 'windDirection' && ts.dimensions.siteId === s.id) as TimeSeries

            const isSelected = (selectedMetSiteId === s.id)



            if (wsts && wdts) {
                const k = DateTime.fromISO(selectedDateTime).set({second: 0}).toUTC().toFormat("yyyy-MM-dd HH:mm:ss");
                const windSpeedMph = (wsts.dimensions.uom == 'M/S' ? mps2miph(wsts.records[k]) : wsts.records[k])
                return MetStationFeature(s, isSelected, zoom, windSpeedMph, wdts.records[k])
            } else {
                return MetStationFeature(s, isSelected, zoom)
            }
        })

        // @TODO look into  ol.module.Collection
        setMetSiteFeatures(newMetSiteFeatures)
    }, [metTimeSeries, selectedDateTime, selectedMetSiteId, zoom])

    useEffect(() => {
        if(metTimeSeries.length <= 0) {
            return;
        }
    }, [metTimeSeries])

    return (
        <MyVectorLayer
            layerName={"metSiteFeatures"}
            features={metSiteFeatures}
            zIndex={zIndex}
        />)
}