interface config {
    API_BASE_URL: string
    USE_LOCAL_DATA: boolean
    COGNITO_USER_POOL_ID: string
    COGNITO_CLIENT_ID: string
    COGNITO_REGION: string
    MIN_ZOOM: number
    MAX_ZOOM: number
}

const conf: config = {
    "API_BASE_URL": process.env.REACT_APP_API_BASE_URL || "",
    "USE_LOCAL_DATA": process.env.REACT_APP_USE_LOCAL_DATA === 'true' || false,
    "COGNITO_USER_POOL_ID": process.env.REACT_APP_COGNITO_USER_POOL_ID || "",
    "COGNITO_CLIENT_ID": process.env.REACT_APP_COGNITO_CLIENT_ID || "",
    "COGNITO_REGION": process.env.REACT_APP_COGNITO_REGION || "",
    "MIN_ZOOM": 14,
    "MAX_ZOOM": 21
}

export default conf