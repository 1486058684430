
// https://www.trysmudford.com/blog/linear-interpolation-functions/
const lerp = function(x: number, y: number, a: number) { return x * (1 - a) + y * a };
const clamp = function(a: number, min: number = 0, max: number = 1) { return Math.min(max, Math.max(min, a)) };
const invlerp = function(x: number, y: number, a: number) { return clamp((a - x) / (y - x)) };
const range = function(x1: number, y1: number, x2: number, y2: number, a: number) { return lerp(x2, y2, invlerp(x1, y1, a)) };

const log_interpolate_range = function(x1: number, x2: number, y1: number, y2: number, a: number) {
    return y2 + ( (Math.log10(a) - Math.log10(x2)) / (Math.log10(x1) - Math.log10(x2)) ) * (y1 - y2)
}

export {lerp, clamp, invlerp, range, log_interpolate_range}