import {FieldRenderProps} from "react-final-form";
import React from "react";

type InputSelectProps = FieldRenderProps<string, any>;

export const InputSelect: React.FC<InputSelectProps> = ({ input, meta, ...rest }: InputSelectProps) => (
    <select {...input} {...rest} />
)

export type InputSelectOption = {
    value: string;
    label: string;
}