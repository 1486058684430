import React from "react";
import {Field} from "react-final-form";
import {InputSelect, InputSelectOption} from "../common/InputSelect";

type EmissionUnitSelectProps = {
    emissionUnitOptions: InputSelectOption[]
}

export const EmissionUnitSelect: React.FC<EmissionUnitSelectProps> = ({emissionUnitOptions}: EmissionUnitSelectProps) => {
    if(emissionUnitOptions.length <= 0) {
        return null;
    }

    return (<Field<string> name={"emissionUnitId"}
                           component={InputSelect}
                           className={"form-control form-control-sm"}
                           defaultValue={emissionUnitOptions[0].value}
    >
        {emissionUnitOptions.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
    </Field>)
}