import React, {PropsWithoutRef} from "react";

export const BootstrapDropdownCustomToggle = React.forwardRef<any>(({children, onClick, className}: PropsWithoutRef<any>, ref) => (
    <a
        href=""
        ref={ref}
        className={className}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
    </a>
));