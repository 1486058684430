import {
    FetchMetTimeSeriesQuery,
    FieldMap,
    ModelCommand,
    MetSite,
    TimeSeries
} from "./types";
import { DateTime } from "luxon";
import {generateTimeSeriesOneDayOfFiveMin, windDirectionsSampleSet, windSpeedsSampleSet} from "./sample/TimeSeries";

export const apiLocal = {
    fetchFieldMap: {
        queryFn(id = "") {
            return fetchFieldMap(id)
        }
    },
    fetchMetTimeSeries: {
        queryFn({date, metStationIds}: FetchMetTimeSeriesQuery) {
            return fetchMetTimeSeriesForSites(date, metStationIds)
        }
    },
    fetchModelInstance: {
        queryFn(id = "") {
             // return fetchForwardDispersionModelResult(id);
             // return fetchReverseDispersionModelResult(id);
             return fetchEmissionsCalculationResult(id);
        }
    },
    putModelInstanceRequest: {
        queryFn(cmd: ModelCommand) {
            return putModelInstanceRequest(cmd);
        }
    },
    fetchModelTypes: {
        queryFn() {
            return fetchModelTypes();
        }
    }
}

const fieldMapJson = require('./sample/fieldMapKOR.json')
const fetchFieldMap = (id: string) => {
    return new Promise<{ data: FieldMap }>((resolve) =>
        setTimeout(() => resolve({
            data: fieldMapJson.fieldMap
        }), 500)
    );
}
const fetchMetTimeSeriesForSites = (date: string, metStationIds: string[]) => {
    let dt = DateTime.fromISO(date)
    return new Promise<{ data: TimeSeries[] }>((resolve) =>
        setTimeout(() => resolve({
            data: fieldMapJson.fieldMap.map_json.met_sites.flatMap((s: MetSite) => {
                return [
                    generateTimeSeriesOneDayOfFiveMin('windSpeed', {"uom":"M/S",'siteId': s.id}, dt, windSpeedsSampleSet),
                    generateTimeSeriesOneDayOfFiveMin('windDirection', {"uom":"M/S",'siteId': s.id}, dt, windDirectionsSampleSet)
                ]
            })
        }), 500)
    );
}
const modelEmissionsCalculationResultJson = require('./sample/modelEmissionsCalculationResultKOR.json')
const fetchEmissionsCalculationResult = (id: string) => {
    return new Promise<{ data: any }>((resolve) =>
        setTimeout(() => resolve({
            data: modelEmissionsCalculationResultJson.modelinstance
        }), 500)
    );
}

const modelForwardDispersionResultJson = require('./sample/modelForwardDispersionResultKOR.json')
const fetchForwardDispersionModelResult = (id: string) => {
    return new Promise<{ data: any }>((resolve) =>
        setTimeout(() => resolve({
             data: modelForwardDispersionResultJson.modelinstance
        }), 500)
    );
}

const modelReverseDispersionResultJson = require('./sample/modelReverseDispersionResultKOR.json')
const fetchReverseDispersionModelResult = (id: string) => {
    return new Promise<{ data: any }>((resolve) =>
        setTimeout(() => resolve({
            data: modelReverseDispersionResultJson.modelinstance
        }), 500)
    );
}

const putModelInstanceRequest = (cmd: ModelCommand) => {
    let data: string;

    switch (cmd.modeltype_id){
        case "54ad096e-adc8-4d81-b177-45c8826c47ee":
            data = modelReverseDispersionResultJson.modelinstance;
            break;
        case "e2cd3548-cec5-4e65-8479-3ca2583659fc":
            data = modelForwardDispersionResultJson.modelinstance;
            break;
        case "f587e7a8-53c9-432d-a4c3-d8e26efddb98":
            data = modelEmissionsCalculationResultJson.modelinstance;
            break;
        default:
            data = "";
            break;
    }

    return new Promise<{ data: any}>( (resolve) =>
        setTimeout( () => resolve({
            data:data
        }))
    );
}

const modelTypeResultJson = require('./sample/modelTypes.json')
const fetchModelTypes = () => {
    return new Promise<{ data: any}>( (resolve) =>
        setTimeout( () => resolve({
            data: modelTypeResultJson.model_types
        }))
    );
}