import React from 'react';
import styles from "./cover.module.css";
import {Field, Form, FormRenderProps} from "react-final-form";
import Spinner from "./Spinner";
import {useResetPasswordMutation} from "./AuthApiSlice";
import {RenderEmailInput} from "./features/common/FinalForm";

interface ForgotPasswordProps extends FormRenderProps {
    errorMessage: string,
    isLoading: boolean
}

const ForgotPasswordForm: React.FC<any> = ({ handleSubmit, isLoading, errorMessage }: ForgotPasswordProps) => {
    return (
        <form onSubmit={handleSubmit}>
            <p>Please provide your email address and we will send a link with which you can reset your password.</p>
            <hr />
            <div className={"input-form form-group mb-3"}>
                <label htmlFor="username" className={"form-label"}>
                    Email Address
                </label>
                <Field<string> name={"username"}
                               component={RenderEmailInput}
                               placeholder={"email@domain.tld"}
                               className={"form-control"}
                />
            </div>
            <div className={"input-form form-group"}>
                <div className="d-grid gap-2">
                    <button className={"btn btn-primary"}
                            type={"submit"}
                            disabled={isLoading}
                    >
                        {isLoading
                            ? <Spinner />
                            : "Request Password Reset"}
                    </button>
                </div>
            </div>
            {!isLoading && errorMessage && <div className={"alert alert-danger"} style={{marginTop: 10}}>
                <p>{errorMessage}</p>
            </div>}
        </form>
    )
}

interface ResetPasswordValues {
    username: string;
}
const resetPasswordInitialValues = {
    username: ""
}

export const ForgotPassword: React.FC = () => {
    const [resetPassword, { isLoading: isSubmittingResetPassword, error: resetPasswordErrorMessage, isSuccess: resetRequestSuccess }] = useResetPasswordMutation()

    const onSubmitResetPassword = (values: ResetPasswordValues) => {
        resetPassword(values)
    }

    return (

        <div className={styles.coverContainer}>
            <div className={"d-flex h-100 p-3 mx-auto flex-column "+styles.coverInner}>
                <header className={"mb-auto"}>
                    <div className={"inner"}>

                    </div>
                </header>
                <main role={"main"} className={"inner "+styles.cover}>
                    <div className={"card"} style={loginStyles.card}>
                        <div className={"card-header"}>
                            <h3>AQ360 Password Reset</h3>
                        </div>
                        <div className={"card-body"}>

                            {!resetRequestSuccess && <Form
                                render={ForgotPasswordForm}
                                initialValues={{
                                    ...resetPasswordInitialValues
                                }}
                                onSubmit={onSubmitResetPassword}
                                isLoading={isSubmittingResetPassword}
                                errorMessage={resetPasswordErrorMessage}
                            />}
                            {resetRequestSuccess &&  <div>
                                <p>Password Reset Requested!</p>
                                <p>Please check your email for a message with a link to reset your password.</p>
                            </div>}
                        </div>
                    </div>
                </main>
                <footer className={"mt-auto"}>
                    <div className={"inner"}>
                    </div>
                </footer>
            </div>
        </div>
    )
}

const loginStyles = {
    card: {
        textAlign: 'left',
        color: '#212529',
        width: '400px'
    } as React.CSSProperties
}