import React from "react";
import {Field} from "react-final-form";
import {InputSelect, InputSelectOption} from "../common/InputSelect";

type MetSiteSelectProps = {
    metStationOptions: InputSelectOption[]
}

export const MetSiteSelect: React.FC<MetSiteSelectProps> = ({metStationOptions}: MetSiteSelectProps) => {
    if(metStationOptions.length <= 0) {
        return null;
    }

    return (<Field<string> name={"metStationId"}
                           component={InputSelect}
                           className={"form-control form-control-sm"}
                           defaultValue={metStationOptions[0].value}
    >
        {metStationOptions.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
    </Field>)
}