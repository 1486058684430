import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import fieldMapReducer from '../features/fieldMap/fieldMapSlice'
import {fieldMapApiSlice} from '../features/fieldMap/fieldMapApiSlice'
import modelReducer from '../features/modeling/modelResultsSlice'
import authReducer from '../AuthSlice'
import {AuthApiSlice} from "../AuthApiSlice";
import {ModelTypeApiSlice} from "../features/modeling/ModelTypeSlice";

export const store = configureStore({
  reducer: {
    fieldMap: fieldMapReducer,
    [fieldMapApiSlice.reducerPath]: fieldMapApiSlice.reducer,
    model: modelReducer,
    auth: authReducer,
    [AuthApiSlice.reducerPath]: AuthApiSlice.reducer
  },
});

store.subscribe(() => {
  localStorage.setItem('aq360AuthState', JSON.stringify(store.getState().auth))
})

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
