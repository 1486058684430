import { Route, Redirect } from 'react-router-dom';
import React, {Component, PropsWithoutRef, ReactChild} from "react";
import {useAppSelector} from "./app/hooks";

interface Props {
    path: string;
    children?: ReactChild | ReactChild[];
}

const PrivateRoute = ({ children, ...rest }: PropsWithoutRef<Props>)  => {

    const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated)

    return <Route {...rest} render={() => (isAuthenticated
        ? children
        : <Redirect to={"/login"} />)} />
}

export default PrivateRoute;