import React from "react";
import {VectorFeatureLayerProps} from "../types";
import {useAppSelector} from "../../../app/hooks";
import {ModelResult, selectAllModelResults} from "../../modeling/modelResultsSlice";
import Feature from "ol/Feature";
import GeoJSON from "ol/format/GeoJSON";
import {get} from "ol/proj";
import {MyVectorLayer} from "../../common/VectorLayer";
import Style from "ol/style/Style";
import Stroke from "ol/style/Stroke";
import Fill from "ol/style/Fill";
import LineString from "ol/geom/LineString";
import Point from "ol/geom/Point";
import Icon from "ol/style/Icon";

import arrow_image from "../../../static/arrow.png"
import arrow_image_blue from "../../../static/arrow_blue.png"

// @TODO change this from string to typed key
//  NOTE An index signature parameter type cannot be a union type. Consider using a mapped object type instead.  TS1337
type StyleHash = {
    [key: string]: Style | Style[]
}

const concentrationPolygonStyles = {
    "0.1": new Style({
        stroke: new Stroke({
            color: 'rgba(0,255,0, 1)',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(0,255,0, .3)'
        }),
    }),
    "0.5": new Style({
        stroke: new Stroke({
            color: 'rgba(0,165,255, 1)',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(0,165,255, .3)'
        }),
    }),
    "0.9": new Style({
        stroke: new Stroke({
            color: 'rgba(0,0,255, 1)',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(0,0,255, .3)'
        }),
    })
} as StyleHash;

const styles = {
    'LESS_LIKELY': new Style({
        stroke: new Stroke({
            color: 'rgba(255,255,0, 1)',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(255,255,0, .3)'
        }),
    }),
    'MODERATELY_LIKELY': new Style({
        stroke: new Stroke({
            color: 'rgba(255,165,0, 1)',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(255,165,0, .3)'
        }),
    }),
    'MOST_LIKELY': new Style({
        stroke: new Stroke({
            color: 'rgba(255,0,0, 1)',
            width: 1,
        }),
        fill: new Fill({
            color: 'rgba(255,0,0, .3)'
        }),
    })
} as StyleHash;

const createArrowsLineStyles = (feature: Feature<LineString>, lineStyle: Style, arrow_image: string, reverse?: boolean) => {
    // https://openlayers.org/en/latest/examples/line-arrows.html

    const geometry = feature.getGeometry()
    const line_styles = []

    line_styles.push(lineStyle)

    let last_segment_point_style = new Style();
    let last_segment_point_style_set = false;

    geometry.forEachSegment((start, end) => {
        const dx = end[0] - start[0];
        const dy = end[1] - start[1];
        const rotation = -1 * Math.atan2(dy, dx) + (reverse ? Math.PI : 0)

        line_styles.push(
            new Style({
                geometry: new Point(end),
                image: new Icon({
                    scale: .15,
                    src: arrow_image,
                    anchor: [1,0.5],
                    rotateWithView: true,
                    rotation: rotation,
                })
            })
        )

        if(!last_segment_point_style_set) {
            last_segment_point_style =  new Style({
                geometry: new Point(start),
                image: new Icon({
                    scale: .15,
                    src: arrow_image,
                    anchor: [1, 0.5],
                    rotateWithView: true,
                    rotation: rotation,
                })
            })
            last_segment_point_style_set = true
        }
    })

    line_styles.push(last_segment_point_style)

    return line_styles
}

const reverseTrajectoryLineStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(255,0,0,1)',
        width: 2
    })
})
const forwardTrajectoryLineStyle = new Style({
    stroke: new Stroke({
        color: 'rgba(0,0,255,1)',
        width: 2
    })
})

const ModelResultFeatureClasses: {[key: string]: Function} = {
    'ProbabilityPolygon': (f: Feature<any>): Feature<any> => {
        let p = f.get('probability')
        f.setStyle(styles[p])
        return f;
    },
    'TrajectoryLine': (f: Feature<any>): Feature<any> => {
        f.setStyle(createArrowsLineStyles(f, reverseTrajectoryLineStyle, arrow_image))
        return f
    },
    'ConcentrationPolygon': (f: Feature<any>): Feature<any> => {
        let p = String(f.get('proportion'))
        f.setStyle(concentrationPolygonStyles[p])
        return f;
    },
    'ReverseTrajectoryLine': (f: Feature<any>): Feature<any> => {
        f.setStyle(createArrowsLineStyles(f, reverseTrajectoryLineStyle, arrow_image))
        return f
    },
    'ForwardTrajectoryLine': (f: Feature<any>): Feature<any> => {
        f.setStyle(createArrowsLineStyles(f, forwardTrajectoryLineStyle, arrow_image_blue, true))
        return f
    },
}

export const ModelResultsLayer: React.FC<VectorFeatureLayerProps> = ({zIndex}: VectorFeatureLayerProps) => {
    const modelResults = useAppSelector(selectAllModelResults) as ModelResult[]

    let modelResultFeatures: Feature<any>[] = []

    if (modelResults.length) {
        modelResultFeatures = modelResults.flatMap((r): Feature<any>[] => {
            if(!r.response_json || r.modeltype_id == "7a390185-9bde-45f1-a61e-d47e49ee7e04") {
                return []
            }

            let features = new GeoJSON().readFeatures(r.response_json, {
                featureProjection: get('EPSG:3857')
            })

            features = features
                .filter(f => ModelResultFeatureClasses[f.getProperties().feature_class] !== undefined)
                .map((f: Feature<any>) => {
                    const feature_class = f.getProperties().feature_class

                    return ModelResultFeatureClasses[feature_class](f)
                })

            return features;
        })
    }

    return (
        <MyVectorLayer
            layerName={"modelResultFeatures"}
            features={modelResultFeatures}
            zIndex={zIndex}
        />
    )
}