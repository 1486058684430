import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {FieldMapJson} from "./types";

const met_soofie_dict = {
    "KERN_OIL_REFINERY-1": "KERN_OIL_REFINERY-1",
    "KERN_OIL_REFINERY-2": "KERN_OIL_REFINERY-1",
    "KERN_OIL_REFINERY-3": "KERN_OIL_REFINERY-1",
    "KERN_OIL_REFINERY-4": "KERN_OIL_REFINERY-1",
    "KERN_OIL_REFINERY-5": "KERN_OIL_REFINERY-1",
    "KERN_OIL_REFINERY-6": "KERN_OIL_REFINERY-1",
    "KERN_OIL_REFINERY-7": "KERN_OIL_REFINERY-15",
    "KERN_OIL_REFINERY-8": "KERN_OIL_REFINERY-8",
    "KERN_OIL_REFINERY-9": "KERN_OIL_REFINERY-11",
    "KERN_OIL_REFINERY-10": "KERN_OIL_REFINERY-8",
    "KERN_OIL_REFINERY-11": "KERN_OIL_REFINERY-11",
    "KERN_OIL_REFINERY-12": "KERN_OIL_REFINERY-15",
    "KERN_OIL_REFINERY-13": "KERN_OIL_REFINERY-15",
    "KERN_OIL_REFINERY-14": "KERN_OIL_REFINERY-11",
    "KERN_OIL_REFINERY-15": "KERN_OIL_REFINERY-15",
    "KERN_OIL_REFINERY-16": "KERN_OIL_REFINERY-15",
    "KERN_OIL_REFINERY-17": "KERN_OIL_REFINERY-15",
    "KERN_OIL_REFINERY-18": "KERN_OIL_REFINERY-18",
    "KERN_OIL_REFINERY-19": "KERN_OIL_REFINERY-19",
    "KERN_OIL_REFINERY-20": "KERN_OIL_REFINERY-19",
}

export interface FieldMapState {
    status: 'idle' | 'loading' | 'failed';
    id: string;
    selectedCoordinate?: number[];
    sourceCoordinateSelected?: number[];
    selectedDateTime?: string;
    selectedMetSiteId?: string;
    selectedMethaneSiteId?: string;
    map_json?: FieldMapJson;
    selectedModelTypeId?: string;
}

const initialState: FieldMapState = {
    id: 'cvx',
    status: 'idle'
};

export interface ModelFormUpdatedPayload {
    selectedDateTime?: string;
    selectedMetSiteId?: string;
    selectedMethaneSiteId?: string;
    sourceCoordinateSelected?: number[]
}


export const fieldMapSlice = createSlice({
    name: 'fieldmap',
    initialState,
    reducers: {
        coordinateSelected(state, action: PayloadAction<number[]>) {
            state.selectedCoordinate = action.payload;
        },
        modelFormUpdated(state, action: PayloadAction<ModelFormUpdatedPayload>) {
            state.selectedDateTime = action.payload.selectedDateTime;
            state.selectedMethaneSiteId = action.payload.selectedMethaneSiteId;
            state.selectedMetSiteId = action.payload.selectedMetSiteId;
            state.sourceCoordinateSelected = action.payload.sourceCoordinateSelected;
        },
        modelTypeUpdated(state, action: PayloadAction<string>) {
            state.selectedModelTypeId = action.payload;
        }
    }
})

export const {coordinateSelected, modelFormUpdated, modelTypeUpdated} = fieldMapSlice.actions;
export default fieldMapSlice.reducer;