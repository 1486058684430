import React, {CSSProperties, PropsWithoutRef} from 'react';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons/faSpinner";
import "@fortawesome/fontawesome-svg-core/styles.css"

interface SpinnerProps extends PropsWithoutRef<any> {
    className?: string,
    style?: CSSProperties | undefined
}

const Spinner: React.FC<SpinnerProps> = ({className, style, ...rest} : SpinnerProps) => {
    let spinnerStyle = style || {color: 'white'}

    return (<FontAwesomeIcon icon={faSpinner} className={'fa-spin '+className} style={spinnerStyle} {...rest} />)
}

export default Spinner;