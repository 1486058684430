import {useAppSelector} from "../../app/hooks";
import {selectUsername} from "../../AuthSlice";
import Dropdown from "react-bootstrap/Dropdown";
import {BootstrapDropdownCustomToggle} from "./BootstrapDropdownCustomToggle";
import {Link} from "react-router-dom";
import React from "react";

export const AuthUserDropdownMenu = () => {

    const username = useAppSelector(selectUsername) as string

    return (
        <Dropdown id={"authDropdown"}>
            <Dropdown.Toggle className="d-flex align-items-center text-white text-decoration-none dropdown-toggle"
                             as={BootstrapDropdownCustomToggle}>
                <strong>{username}</strong>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-menu dropdown-menu-dark text-small shadow">
                <Dropdown.Item>
                    <Link to={"/logout"} style={{color: 'white', textDecoration: 'none', display:"block", width:'100%',height:'100%'}}>
                        Sign out
                    </Link>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    )
}