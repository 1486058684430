import React, {useEffect} from 'react';
import styles from './cover.module.css'
import Spinner from "./Spinner";

const COLD_BOOT_THRESHOLD_SECONDS = 10;

const LoadingCover: React.FC = () => {

    const [isColdBoot, setIsColdBoot] = React.useState(false)

    useEffect(() => {
        let timeoutId = setTimeout(() => {
            setIsColdBoot(true)
        }, COLD_BOOT_THRESHOLD_SECONDS * 1000)

        return () => clearTimeout(timeoutId)
    }, [])

    return (
        <div className={styles.coverContainer}>
            <div className={"d-flex h-100 p-3 mx-auto flex-column "+styles.coverInner}>
                <header className={"mb-auto"}>
                    <div className={"inner"}>

                    </div>
                </header>
                <main role={"main"} className={"inner "+styles.cover}>
                    <p className={"lead text-center"}>
                        <Spinner className={"fa-2x"} />
                    </p>
                    <h1 className={"text-center"}>
                        Loading
                    </h1>
                    {isColdBoot && <p className={"text-center"}>
                        (This may take a minute)
                    </p>}
                </main>
                <footer className={"mt-auto"}>
                    <div className={"inner"}>
                    </div>
                </footer>
            </div>
        </div>
    )
}

export default LoadingCover;